import { jsx as _jsx } from "react/jsx-runtime";
import dayjs from 'dayjs';
import { createContext, useCallback, useContext } from 'react';
import { useState, useEffect } from 'react';
export const AuthContext = createContext({
    token: null,
    isLoading: false,
    loginFailed: false,
    isAuthenticated: false,
    login: () => { },
    logout: () => { },
    isTokenExpired: () => true,
});
function isTokenExpired() {
    const expiresAt = localStorage.getItem(expiresKey);
    return !expiresAt || dayjs().isAfter(dayjs(expiresAt).subtract(5, 'seconds'));
}
const tokenKey = '__billing-token__';
const expiresKey = '__billing-expires__';
const isSuccessfulLogin = (body) => (body === null || body === void 0 ? void 0 : body.status) === 'OK';
// Custom hook for authentication
export function AuthProvider({ children }) {
    const [token, setToken] = useState(() => localStorage.getItem(tokenKey));
    const [status, setStatus] = useState('idle');
    const login = useCallback(({ email, password }) => {
        setStatus('loading');
        fetch(`${process.env.API_ENDPOINT}/v2/auth/login`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ email, password }),
            mode: 'cors',
        })
            .then((response) => response.json())
            .then((responseBody) => {
            if (isSuccessfulLogin(responseBody)) {
                setStatus('idle');
                localStorage.setItem(expiresKey, responseBody.payload.expiresAt);
                localStorage.setItem(tokenKey, responseBody.payload.token);
                setToken(responseBody.payload.token);
            }
            else {
                throw new Error('Login failed');
            }
        })
            .catch(() => {
            logout();
            setStatus('error');
        });
    }, []);
    const refresh = useCallback(() => {
        setStatus('loading');
        fetch(`${process.env.API_ENDPOINT}/v2/auth/refresh`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            credentials: 'include',
            mode: 'cors',
        })
            .then((response) => response.json())
            .then((responseBody) => {
            if (isSuccessfulLogin(responseBody)) {
                setStatus('idle');
                localStorage.setItem(expiresKey, responseBody.payload.expiresAt);
                localStorage.setItem(tokenKey, responseBody.payload.token);
                setToken(responseBody.payload.token);
            }
            else {
                throw new Error('refresh failed');
            }
        })
            .catch(() => {
            logout();
            setStatus('idle');
        });
    }, [token]);
    const logout = () => {
        fetch(`${process.env.API_ENDPOINT}/v2/auth/logout`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
        }).catch(() => { });
        localStorage.removeItem(expiresKey);
        localStorage.removeItem(tokenKey);
        setToken(null);
    };
    useEffect(() => {
        if (token && isTokenExpired()) {
            refresh();
        }
    }, [token]);
    return (_jsx(AuthContext.Provider, { value: {
            token,
            isAuthenticated: !!token,
            login,
            logout,
            isTokenExpired,
            isLoading: status === 'loading',
            loginFailed: status === 'error',
        }, children: children }));
}
export function useAuth() {
    return useContext(AuthContext);
}
