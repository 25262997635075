import { jsx as _jsx } from "react/jsx-runtime";
import MarkerClusterGroup from 'react-leaflet-markercluster';
import L from 'leaflet';
import styles from './MarkerClusterCircle.module.scss';
import { classNames } from '../utils/helperFunctions';
import { IconSingleMapPin } from '../Icon';
import { renderToString } from 'react-dom/server';
const icon = renderToString(_jsx(IconSingleMapPin, { color: "inverse", size: "standard" }));
const createClusterCustomIcon = function (cluster) {
    return L.divIcon({
        html: `<div><span>${cluster.getChildCount()}</span> ${icon}<div>`,
        className: classNames(styles.MarkerType, styles[`MarkerType_black`]),
        iconSize: L.point(32, 32, true),
    });
};
export const MarkerClusterCircle = ({ children, type = 'black', maxClusterRadius = 0, key = 'MarkerClusterCircle', }) => {
    return (_jsx(MarkerClusterGroup, { maxClusterRadius: maxClusterRadius, iconCreateFunction: createClusterCustomIcon, children: children }, key));
};
