import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MapContainer, TileLayer } from 'react-leaflet';
import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useState, } from 'react';
import 'leaflet/dist/leaflet.css';
import styles from './GordonMap.module.scss';
import { LatLngBounds } from 'leaflet';
import { IconMark, IconMinus, IconPlus, IconXClose } from '../Icon';
import { Button } from '../Button';
import { ControlBar } from './ControlBar';
import throttle from 'lodash/throttle';
import { simpleId } from '../utils/helperFunctions';
const MapContext = React.createContext(React.createRef());
export const useMap = () => useContext(MapContext);
export const usePerformanceMap = (initialBounds, zoomLevel = 12) => {
    const [shouldUsePerformanceMarker, setShouldUsePerformanceMarker] = useState(true);
    const [latLngBounds, setLatLngBounds] = useState(() => new LatLngBounds(initialBounds));
    const [renderId, setRenderId] = useState(() => simpleId());
    const ref = useMap();
    const isCoordinatesOnScreen = useCallback((coordinates) => {
        return shouldUsePerformanceMarker || latLngBounds.contains(coordinates);
    }, [latLngBounds, shouldUsePerformanceMarker]);
    const setRender = () => {
        setRenderId(simpleId());
    };
    return {
        shouldUsePerformanceMarker,
        isCoordinatesOnScreen,
        renderId,
        mapProps: {
            preferCanvas: true,
            whenReady: () => {
                var _a, _b;
                const onMove = throttle(() => {
                    var _a;
                    if (!ref.current) {
                        return;
                    }
                    if (ref.current.getZoom() < zoomLevel) {
                        setShouldUsePerformanceMarker(true);
                    }
                    else {
                        setLatLngBounds((_a = ref.current) === null || _a === void 0 ? void 0 : _a.getBounds());
                        setShouldUsePerformanceMarker(false);
                    }
                }, 100);
                (_a = ref.current) === null || _a === void 0 ? void 0 : _a.on('move', onMove);
                (_b = ref.current) === null || _b === void 0 ? void 0 : _b.on('moveend', setRender);
                return () => {
                    var _a, _b;
                    (_a = ref.current) === null || _a === void 0 ? void 0 : _a.off('moveend', setRender);
                    (_b = ref.current) === null || _b === void 0 ? void 0 : _b.off('move', onMove);
                };
            },
        },
    };
};
export const options = { paddingTopLeft: [15, 30], paddingBottomRight: [15, 0] };
// eslint-disable-next-line react/display-name
export const GordonMap = forwardRef(({ children, bounds, fitBoundOptions, customControl, minZoom, preferCanvas, keyboard, onClose, whenReady, disabledInteractions, }, ref) => {
    const internalRef = useMap();
    const [ready, setReady] = useState(false);
    useImperativeHandle(ref, () => internalRef.current);
    useEffect(() => {
        return () => {
            if (internalRef.current) {
                internalRef.current.off();
                internalRef.current.remove();
            }
        };
    }, []);
    useEffect(() => {
        if (ready) {
            return whenReady === null || whenReady === void 0 ? void 0 : whenReady();
        }
    }, [ready]);
    const initialBounds = useMemo(() => {
        return bounds;
    }, []);
    return (_jsxs("div", { className: styles.GordonMap, children: [_jsxs(MapContainer, { bounds: initialBounds, id: "live-tracking-map", boundsOptions: fitBoundOptions !== null && fitBoundOptions !== void 0 ? fitBoundOptions : options, ref: internalRef, zoomControl: false, minZoom: minZoom, preferCanvas: preferCanvas, keyboard: keyboard, whenReady: () => setReady(true), children: [_jsx(TileLayer, { url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png" }), children] }), _jsxs("div", { className: styles.Controls, children: [onClose && (_jsx(Button, { icon: IconXClose, iconType: "only", variant: "secondary", text: "Close", onClick: onClose })), customControl, _jsxs(ControlBar, { children: [_jsx("button", { "aria-label": "reset zoom and position on map", disabled: disabledInteractions, onClick: () => { var _a; return (_a = internalRef.current) === null || _a === void 0 ? void 0 : _a.fitBounds(bounds, fitBoundOptions); }, children: _jsx(IconMark, { color: disabledInteractions ? 'disabled' : 'strong' }) }), _jsx("button", { "aria-label": "zoom in", disabled: disabledInteractions, onClick: () => { var _a; return (_a = internalRef.current) === null || _a === void 0 ? void 0 : _a.zoomIn(); }, children: _jsx(IconPlus, { color: disabledInteractions ? 'disabled' : 'strong' }) }), _jsx("button", { "aria-label": "zoom out", disabled: disabledInteractions, onClick: () => { var _a; return (_a = internalRef.current) === null || _a === void 0 ? void 0 : _a.zoomOut(); }, children: _jsx(IconMinus, { color: disabledInteractions ? 'disabled' : 'strong' }) })] })] })] }));
});
