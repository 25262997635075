export const toKeys = (o) => Object.keys(o);
export const toValues = (o) => Object.values(o);
export const toEntries = (o) => Object.entries(o);
export const removeKey = (obj, key) => {
    const newObj = Object.assign({}, obj);
    if (newObj[key])
        delete newObj[key];
    return newObj;
};
export function isKeyOfObject(key, obj) {
    return key in obj;
}
export const isDefined = (value) => {
    return value !== undefined && value !== null;
};
export const isTruthy = (value) => Boolean(value);
/*
Example usage of arrayUpsert (_ variable in example is the previous result above):

> []
[]
> arrayUpsert(_, { id: 'a', state: 'pending'}, 'id')
[ { id: 'a', state: 'pending' } ]
> arrayUpsert(_, { id: 'a', retry: 2}, 'id')
[ { id: 'a', state: 'pending', retry: 2 } ]
> arrayUpsert(_, { id: 'a', state: 'duplicate_because_not_same_reference'})
[
  { id: 'a', state: 'pending', retry: 2 },
  { id: 'a', state: 'duplicate_because_not_same_reference' }
]
> arrayUpsert(_, [{ id: 'b'}, {id: 'c'}, {id: 'b', state: 'bulk insert'}], 'id')
[
  { id: 'a', state: 'pending', retry: 2 },
  { id: 'a', state: 'duplicate_because_not_same_ref' },
  { id: 'b', state: 'bulk insert' },
  { id: 'c' }
]

It can also be used with strings, numbers, etc. without a key and then it works more like a set (only one entry per value and no duplicates)
*/
export function arrayUpsert(array, item, key) {
    if (Array.isArray(item)) {
        return item.reduce((acc, i) => arrayUpsertSingle(acc, i, key), array !== null && array !== void 0 ? array : []);
    }
    return arrayUpsertSingle(array, item, key);
}
function arrayUpsertSingle(array, item, key) {
    if (!array)
        return [item];
    const index = key ? array.findIndex((i) => i[key] === item[key]) : array.indexOf(item);
    if (index === -1) {
        return [...array, item];
    }
    const newArray = [...array];
    const oldValue = newArray[index];
    newArray[index] = typeof oldValue === 'object' ? Object.assign(Object.assign({}, newArray[index]), item) : item;
    return newArray;
}
