import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, Column, HeaderColumn, IconEdit1, IconMessageTextSquare, MeatballMenu, MenuListItem, Page, PageSection, Row, Table, TableBody, TableHead, } from '@gordon/web-ui';
import { useFetchAccounts } from '../api';
import { useState } from 'react';
import { SearchInput } from '../components/SearchInput';
import { FilterBar } from '../components/FilterBar';
import { useQueryParamState } from '../hooks/useQueryParamState';
import { useSort } from '../hooks/useSort';
import { FilterDropdown } from '../components/FilterDropdown';
import { useFilter } from '../hooks/useFilter';
import { useSearch } from '../hooks/useSearch';
import { accountFilterOptions, invoicePeriodOptions, monthlySplitOptions, statusOptions, } from '../utils/options';
import { capitalizeFirstLetter } from '../utils/helpers';
import { UpdateAccountDetailsModal } from '../modals/UpdateAccountDetailsModal';
import { AddAccountNotesModal } from '../modals/AddAccountNotesModal';
export const Accounts = () => {
    const [showUpdateAccountDetailsModal, setShowUpdateAccountDetailsModal] = useState();
    const [state, setState, hasChanged, clearAll] = useQueryParamState({
        invoicePeriod: new Array(),
        monthlySplit: new Array(),
        accountType: new Array(),
        search: '',
        status: '',
    });
    const [showAddAccountNoteModal, setShowAddAccountNoteModal] = useState();
    const { data, runRequest, isLoading } = useFetchAccounts(state.status);
    const searchedList = useSearch(data, ['name', 'periodicity'], state.search);
    const filteredList = useFilter(searchedList, {
        periodicity: state.invoicePeriod,
        splitInvoiceSpecByMonth: state.monthlySplit,
        accountType: state.accountType,
    });
    const { sortedList: accounts, sortField, sortOrder, changeSorting } = useSort(filteredList);
    return (_jsxs(Page, { children: [_jsx(PageSection, { size: "h1", headingText: "Accounts", actionsLeft: _jsxs(FilterBar, { clear: hasChanged ? clearAll : undefined, children: [_jsx(SearchInput, { placeholder: "Search", defaultValue: state.search, onEnter: (value) => setState('search', value) }), _jsx(FilterDropdown, { options: accountFilterOptions, value: state.accountType, placeholder: "Account type", onUpdate: (value) => setState('accountType', value), multiselect: true }), _jsx(FilterDropdown, { options: statusOptions, value: state.status, placeholder: "Status", onUpdate: (value) => setState('status', value !== null && value !== void 0 ? value : '') }), _jsx(FilterDropdown, { options: monthlySplitOptions, value: state.monthlySplit, placeholder: "Monthly Split", multiselect: true, onUpdate: (value) => {
                                setState('monthlySplit', value);
                            } }), _jsx(FilterDropdown, { width: 142, options: invoicePeriodOptions, value: state.invoicePeriod, multiselect: true, onUpdate: (value) => {
                                setState('invoicePeriod', value);
                            }, placeholder: "Invoice period" })] }), children: _jsxs(Table, { fullBorder: true, children: [_jsxs(TableHead, { title: accounts && !isLoading ? `${accounts.length} accounts` : 'Loading', children: [_jsx(HeaderColumn, { isSortable: true, sortOrder: sortOrder, isSelected: sortField === 'name', onClick: () => changeSorting('name'), children: "Account name" }), _jsx(HeaderColumn, { isSortable: true, isSelected: sortField === 'accountType', onClick: () => changeSorting('accountType'), children: "Type" }), _jsx(HeaderColumn, { width: 300, isSortable: true, sortOrder: sortOrder, isSelected: sortField === 'isActive', onClick: () => changeSorting('isActive'), children: "Status" }), _jsx(HeaderColumn, { width: 300, isSortable: true, sortOrder: sortOrder, isSelected: sortField === 'splitInvoiceSpecByMonth', onClick: () => changeSorting('splitInvoiceSpecByMonth'), children: "Monthly split" }), _jsx(HeaderColumn, { width: 300, isSortable: true, sortOrder: sortOrder, isSelected: sortField === 'periodicity', onClick: () => changeSorting('periodicity'), children: "Invoice period" }), _jsx(HeaderColumn, { width: 48 })] }), _jsx(TableBody, { children: (accounts === null || accounts === void 0 ? void 0 : accounts.length)
                                ? accounts.map((account) => (_jsxs(Row, { children: [_jsx(Column, { text: account.name, href: `/accounts/${account._id}` }), _jsx(Column, { text: account.accountType ? account.accountType : '-' }), _jsx(Column, { children: _jsx("span", { style: { display: 'flex', justifyContent: 'left' }, children: _jsx(Badge, { text: account.isActive ? 'Active' : 'Inactive', variant: account.isActive ? 'success' : 'error' }) }) }), _jsx(Column, { text: account.splitInvoiceSpecByMonth ? 'Yes' : 'No' }), _jsx(Column, { text: account.periodicity ? capitalizeFirstLetter(account.periodicity) : '-' }), _jsx(Column, { children: _jsxs(MeatballMenu, { hasNotification: !!account.note, children: [_jsx(MenuListItem, { text: "Edit account details", onClick: () => setShowUpdateAccountDetailsModal(account), value: "", leadingElement: _jsx(IconEdit1, {}) }), _jsx(MenuListItem, { text: "Add account notes", onClick: () => setShowAddAccountNoteModal(account), value: "", leadingElement: _jsx(IconMessageTextSquare, { hasNotification: !!account.note }) })] }) })] }, account._id)))
                                : !isLoading && (_jsx(Row, { children: _jsx(Column, { span: 100, text: "No accounts found" }) })) })] }) }), showUpdateAccountDetailsModal && (_jsx(UpdateAccountDetailsModal, { account: showUpdateAccountDetailsModal, onSubmit: () => {
                    runRequest();
                    setShowUpdateAccountDetailsModal(null);
                }, onClose: () => setShowUpdateAccountDetailsModal(null) })), showAddAccountNoteModal && (_jsx(AddAccountNotesModal, { account: showAddAccountNoteModal, onSubmit: () => {
                    runRequest();
                    setShowAddAccountNoteModal(null);
                }, onClose: () => setShowAddAccountNoteModal(null) }))] }));
};
