import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './Tabs.module.scss';
import { NavLink, matchPath } from 'react-router-dom';
import { classNames } from '../utils/helperFunctions';
import { Badge } from '../Badge';
function getPathFromUrl(url) {
    return url.split(/[?#]/)[0];
}
const isCurrentPage = (href) => {
    return !!matchPath(window.location.pathname, getPathFromUrl(href));
};
export function Tabs({ onClick = () => { }, size = 'medium', tabs, selectedTab, inTable, inList, }) {
    return (_jsx("div", { className: classNames(styles.tabs, inTable && styles['tabs--in-table'], inList && styles['tabs--in-list']), children: _jsx("div", { id: "tabs-container", role: "tablist", children: tabs.map((tab) => {
                const isSelected = tab.value === selectedTab;
                return tab.href && !tab.disabled ? (_jsxs(NavLink, { role: "tab", to: tab.href, className: classNames(styles.tab, styles[`size--${size}`]), activeClassName: styles['tab--active'], children: [tab.text, tab.badge &&
                            (typeof tab.badge === 'string' ? (_jsx(Badge, { text: tab.badge, size: "small", variant: 'grey' })) : (tab.badge))] }, tab.value)) : (_jsxs("button", { type: "button", "aria-selected": isSelected, role: "tab", className: classNames(styles.tab, styles[`size--${size}`], isSelected && styles['tab--active']), disabled: tab.disabled, onClick: !tab.disabled ? () => onClick(tab.value) : undefined, children: [tab.text, tab.badge &&
                            (typeof tab.badge === 'string' ? (_jsx(Badge, { text: tab.badge, size: "small", variant: tab.disabled ? 'disabled' : 'grey' })) : (tab.badge))] }, tab.value));
            }) }) }));
}
