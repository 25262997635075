import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { classNames } from '../utils/helperFunctions';
import styles from './Row.module.scss';
import { Column } from './Column';
import { TableContext } from './shared';
import { IconChevronDown, IconChevronUp } from '../Icon';
import { Button } from '../Button';
export function Row({ children, selected, expandedRow, noBorder, badges, grouped, }) {
    const { expandable, condensed } = useContext(TableContext);
    const [isExpanded, setExpanded] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsxs("tr", { className: classNames(styles.base, condensed && styles.condensed, (selected || isExpanded) && styles.selected, !!badges && styles.badges, noBorder && styles.NoBorder, grouped && styles.grouped), children: [children, expandable && (_jsx(Column, { children: _jsx(Button, { onClick: () => setExpanded(!isExpanded), variant: "tertiary", disabled: !expandedRow, icon: isExpanded ? IconChevronUp : IconChevronDown, text: "expand", iconType: "only" }) }))] }), badges && (_jsx("tr", { className: classNames(styles.base, (selected || isExpanded) && styles.selected, grouped && styles.grouped), children: _jsx("td", { colSpan: 100, children: _jsx("div", { children: badges }) }) })), isExpanded && (_jsx("tr", { className: classNames(styles.base, (selected || isExpanded) && styles.selected, grouped && styles.grouped), children: _jsx("td", { colSpan: 100, children: expandedRow }) }))] }));
}
