import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from '../utils/helperFunctions';
import styles from './InputLabels.module.scss';
import { Paragraph } from '../Typography';
import { IconAlertCircle } from '../Icon';
export function InputLabels({ disabled = false, label = '', hint = '', errorMessage = '', children, labelFor, alignment = 'top', // top / left
size = 'small', }) {
    const isSizeSmall = size === 'small';
    return (_jsxs("div", { className: classNames(styles.base, alignment === 'left' && styles['alignment-left']), children: [label && (_jsx("label", { style: { display: 'flex', flexDirection: 'row', gap: 4 }, htmlFor: labelFor, children: _jsx(Paragraph, { size: isSizeSmall ? 'standard' : 'large', strong: true, color: disabled ? 'disabled' : 'default', children: label }) })), children, errorMessage ? (_jsxs("div", { className: styles['extra-info'], children: [_jsx(IconAlertCircle, { size: isSizeSmall ? 'small' : 'standard', color: "error" }), _jsx(Paragraph, { size: isSizeSmall ? 'small' : 'standard', color: "error", children: errorMessage })] })) : (hint && (_jsx("div", { className: styles['extra-info'], children: _jsx(Paragraph, { size: isSizeSmall ? 'small' : 'standard', color: "subtle", children: hint }) })))] }));
}
