import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef, useCallback, useMemo } from 'react';
import styles from './Select.module.scss';
import { InputLabels } from '../InputLabels/InputLabels';
import { SelectList } from './SelectList';
import { useDetectClickOutside } from '../utils/hooks';
import { SelectButton, TagList } from './SelectButton';
import { simpleId } from '../utils/helperFunctions';
import { Tag } from '../Tag';
import { Paragraph } from '../Typography';
import { Badge } from '../Badge';
export const Select = (props) => {
    const { icon, label = '', hint = '', errorMessage = '', options = [], // {value, text, leadingElement, disabled, trailingElement, selected}, leadingElement/disabled/trailingElement is optional, selected is used for multiselect.
    placeholder = '', alignment = 'left', disabled, withSearch = false, initiallyExpanded = false, variant = 'primary', // "primary" / "tertiary,
    selectedIcon, selectListWidth, selectWidth, isLoading, popup, size = 'small', } = props;
    const id = useMemo(() => simpleId(), []);
    const [isExpanded, setExpanded] = useState(initiallyExpanded);
    return (_jsx("div", { className: styles.base, children: _jsx(InputLabels, { label: label, labelFor: id, hint: hint, size: size, errorMessage: errorMessage, disabled: disabled, alignment: variant === 'tertiary' ? 'left' : 'top', children: _jsx("div", { className: variant === 'primary' ? styles.wrapper : undefined, children: props.multiselect ? (_jsx(MultiSelect, { id: id, isLoading: isLoading, variant: variant, withSearch: withSearch, isExpanded: isExpanded, setExpanded: setExpanded, disabled: disabled, placeholder: placeholder, icon: icon, options: options, value: props.value, onSelect: props.onSelect, preview: props.preview, alignment: alignment, error: !!errorMessage, selectedIcon: selectedIcon, selectListWidth: selectListWidth, selectWidth: selectWidth, withSelectAll: props.withSelectAll, popup: popup, size: size })) : (_jsx(SingleSelect, { id: id, variant: variant, isLoading: isLoading, withSearch: withSearch, allowUnselect: props.allowUnselect, isExpanded: isExpanded, setExpanded: setExpanded, disabled: disabled, placeholder: placeholder, icon: icon, options: options, value: props.value, onSelect: props.onSelect, alignment: alignment, error: !!errorMessage, selectedIcon: selectedIcon, selectListWidth: selectListWidth, selectWidth: selectWidth, popup: popup, size: size })) }) }) }));
};
const MultiSelect = ({ id, withSearch, preview = 'selection', options, isExpanded, isLoading, setExpanded, value, placeholder, disabled, icon, onSelect, alignment, error, selectedIcon, selectListWidth, selectWidth, withSelectAll, popup, size = 'small', }) => {
    const headRef = useRef(null);
    const contentRef = useRef(null);
    const close = useCallback(() => {
        setExpanded(false);
    }, [setExpanded, headRef]);
    useDetectClickOutside(isExpanded, close, headRef, contentRef);
    const onChange = useCallback((clickedValue) => {
        onSelect(value.includes(clickedValue)
            ? value.filter((v) => v !== clickedValue)
            : [...value, clickedValue], clickedValue);
    }, [value, setExpanded, onSelect]);
    const selectedOptions = options.filter((option) => value.includes(option.value));
    const calculateWidth = (str, minWidth) => {
        if (preview === 'count') {
            const selectedCountLength = 20;
            const totalValue = str.length * 10 + selectedCountLength;
            return totalValue < minWidth ? minWidth : totalValue;
        }
    };
    const minWidth = 100;
    const result = calculateWidth(placeholder, minWidth);
    return (_jsxs(_Fragment, { children: [_jsx(SelectButton, { id: id, ref: headRef, isExpanded: isExpanded, placeholder: preview === 'count' && selectedOptions.length > 0
                    ? `${placeholder} (${selectedOptions.length})`
                    : placeholder, setExpanded: setExpanded, isLoading: isLoading, disabled: disabled, icon: icon, error: error, calculatedWidth: result, preview: preview, selectWidth: selectWidth, clearable: !disabled && selectedOptions.length > 0, clearSelected: () => onSelect([], ''), size: size, children: selectedOptions.length > 0 && preview === 'selection' && (_jsx(TagList, { children: selectedOptions.map((option) => (_jsx(Tag, { disabled: disabled, onClick: (e) => {
                            e.stopPropagation();
                            onChange(option.value);
                        }, children: option.text }))) })) }), isExpanded && (_jsx(SelectList, { id: id, withSearch: withSearch, close: close, ref: contentRef, checkIfSelected: (item) => value.includes(item), onChange: onChange, options: options, alignment: alignment, selectedIcon: selectedIcon, selectListWidth: selectListWidth, withSelectAll: withSelectAll, onSelect: onSelect, popup: popup, multi: true, size: size }))] }));
};
const SingleSelect = ({ id, variant, withSearch, allowUnselect, options, isExpanded, setExpanded, value, placeholder, disabled, icon, onSelect, isLoading, alignment, error, selectedIcon: SelectedIcon, selectListWidth, selectWidth, popup, size = 'small', }) => {
    const headRef = useRef(null);
    const contentRef = useRef(null);
    const close = useCallback(() => {
        setExpanded(false);
    }, [setExpanded, headRef]);
    useDetectClickOutside(isExpanded, close, headRef, contentRef);
    const onChange = useCallback((clickedValue) => {
        if (clickedValue === value && allowUnselect === false) {
            return close();
        }
        onSelect(clickedValue === value ? undefined : clickedValue);
        close();
    }, [value, allowUnselect, setExpanded, onSelect]);
    const selectedColor = disabled ? 'disabled' : variant === 'tertiary' ? 'brand-primary' : 'strong';
    const selectedOption = options.find((option) => value === option.value);
    return (_jsxs(_Fragment, { children: [_jsx(SelectButton, { id: id, isLoading: isLoading, variant: variant, ref: headRef, isExpanded: isExpanded, placeholder: placeholder, setExpanded: setExpanded, disabled: disabled, icon: icon, error: error, selectWidth: selectWidth, clearable: allowUnselect && (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.value) !== undefined, clearSelected: () => onSelect(undefined), size: size, children: selectedOption && (_jsxs(_Fragment, { children: [_jsx(Paragraph, { size: size === 'small' ? 'standard' : 'large', color: selectedColor, strong: variant === 'tertiary', children: selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.text }), selectedOption.badgeVariant && (_jsx(Badge, { variant: selectedOption.badgeVariant, text: selectedOption.badge, shape: selectedOption.badgeShape, type: selectedOption.badgeType, icon: selectedOption.badgeIcon, size: "small" })), SelectedIcon && (_jsx(SelectedIcon, { color: selectedColor, size: size === 'small' ? 'standard' : 'large' }))] })) }), isExpanded && (_jsx(SelectList, { id: id, withSearch: withSearch, close: close, ref: contentRef, checkIfSelected: (item) => item === value, onChange: onChange, options: options, alignment: alignment, selectedIcon: SelectedIcon, selectListWidth: selectListWidth, popup: popup, size: size }))] }));
};
