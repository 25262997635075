import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
import { Heading, useDeviceWidth } from '@gordon/web-ui';
import styles from './Form.module.scss';
const FormContext = createContext({ onEditMode: false });
export const useFormOnEditMode = () => {
    return useContext(FormContext);
};
export const Form = ({ title, children, rightAction, titleSize = 'h4', onEditMode = false, }) => {
    const { isMobile } = useDeviceWidth();
    return (_jsx(FormContext.Provider, { value: { onEditMode }, children: _jsxs("div", { children: [title && (_jsxs("div", { className: styles.TitleActionWrapper, children: [_jsx(Heading, { size: titleSize, color: "strong", children: title }), !isMobile && rightAction && _jsx("div", { children: rightAction })] })), _jsx("ul", { className: styles.FormWrapper, children: children }), isMobile && rightAction && _jsx("div", { children: rightAction })] }) }));
};
