import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useCallback } from 'react';
import styles from '../Link/Link.module.scss';
import btnLinkStyles from '../ButtonLink/ButtonLink.module.scss';
import { classNames } from '../utils/helperFunctions';
const variantToIconColor = {
    primary: 'brand-primary',
    secondary: 'default',
    tertiary: 'default',
    'tertiary-color': 'strong',
    destructive: 'error',
    'tertiary-error': 'strong',
    inverse: 'inverse',
};
export const ButtonLink = forwardRef(({ text, size = 'standard', variant = 'secondary', underline = false, type = 'button', disabled = false, active = false, onClick, ariaControls, ariaExpanded, ariaHaspopup, ariaLabel, tabIndex, icon: Icon, iconType, }, ref) => {
    const clickHandler = useCallback((event) => {
        event.stopPropagation();
        if (!disabled && onClick) {
            return onClick();
        }
    }, [disabled, onClick]);
    const iconColor = disabled ? 'disabled' : variantToIconColor[variant];
    return (_jsxs("button", { ref: ref, type: type, title: text, onClick: clickHandler, tabIndex: tabIndex, "aria-controls": ariaControls, "aria-expanded": ariaExpanded, "aria-haspopup": ariaHaspopup, disabled: disabled, "aria-label": ariaLabel, className: classNames(iconType && btnLinkStyles.withIcon, styles.baseButtonLink, styles[`variant-${variant}`], styles[`size--${size}`], active && styles[`variant-${variant}--active`], disabled && styles[`variant-${variant}--disabled`], underline && styles['underline']), children: [iconType === 'leading' && Icon && _jsx(Icon, { size: "small", color: iconColor }), text, iconType === 'trailing' && Icon && _jsx(Icon, { size: "small", color: iconColor })] }));
});
ButtonLink.displayName = 'ButtonLink';
