import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './Alert.module.scss';
import { IconAlertCircle, IconAlertTriangle, IconCheckCircle, IconXClose } from '../Icon';
import { classNames } from '../utils/helperFunctions';
import { Paragraph } from '../Typography';
import { Button } from '../Button';
function Icon({ variant }) {
    switch (variant) {
        case 'warning':
            return _jsx(IconAlertTriangle, { color: "warning" });
        case 'success':
            return _jsx(IconCheckCircle, { color: "success" });
        case 'primary':
            return _jsx(IconAlertCircle, { color: "brand-strong" });
        case 'error':
            return _jsx(IconAlertCircle, { color: "error" });
        default:
            return _jsx(IconAlertCircle, {});
    }
}
export function Alert({ variant = 'primary', heading, supportingText, onClose, children, }) {
    return (_jsx("div", { role: "alert", className: classNames(styles.base, styles[`variant--${variant}`]), children: _jsxs("div", { className: styles['alert-content-wrapper'], children: [_jsx("div", { className: styles.icon, children: _jsx(Icon, { variant: variant }) }), _jsxs("div", { className: styles.content, children: [_jsxs("div", { children: [_jsx(Paragraph, { strong: true, children: heading }), supportingText && _jsx(Paragraph, { children: supportingText })] }), children] }), onClose && (_jsx("div", { className: styles['close-button'], children: _jsx(Button, { variant: "tertiary", ariaLabel: "Alert close button", onClick: onClose, iconType: "only", text: "Alert close button", size: "small", icon: IconXClose }) }))] }) }));
}
