var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import styles from './SelectListItem.module.scss';
import { IconCheckCircle } from '../Icon';
import { Paragraph } from '../Typography';
import { Badge } from '../Badge';
import { Checkbox } from '../Checkbox';
import { classNames } from '../utils/helperFunctions';
export const SelectListItem = (_a) => {
    var { disabled, isSelected, text, icon: Icon, value, multi, onChange, highlighted, noTabIndex, size = 'small', selectedIcon: SelectedIcon = IconCheckCircle } = _a, badgeProps = __rest(_a, ["disabled", "isSelected", "text", "icon", "value", "multi", "onChange", "highlighted", "noTabIndex", "size", "selectedIcon"]);
    const ref = useRef(null);
    const isSizeSmall = size === 'small';
    useEffect(() => {
        var _a;
        if (highlighted) {
            (_a = ref.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ block: 'nearest', inline: 'nearest' });
        }
    }, [highlighted]);
    const handleKeyDown = (event) => {
        if (event.code === 'Space') {
            event.preventDefault();
            onChange(value);
        }
    };
    const onClick = () => {
        onChange(value);
    };
    return (_jsxs("li", { ref: ref, className: classNames(styles.base, highlighted && styles.highlighted), role: "option", "aria-disabled": disabled, "aria-selected": disabled ? undefined : isSelected, tabIndex: disabled || noTabIndex ? -1 : 0, onKeyDown: disabled ? undefined : handleKeyDown, onClick: disabled ? undefined : onClick, onMouseDown: (e) => {
            e.preventDefault();
        }, children: [multi && _jsx(Checkbox, { onChange: () => null, checked: isSelected, disabled: disabled }), Icon && _jsx(Icon, { color: disabled ? 'disabled' : 'default' }), _jsxs("span", { className: styles.content, children: [isSelected ? (_jsx(Paragraph, { size: isSizeSmall ? 'standard' : 'large', strong: true, color: disabled ? 'disabled' : 'strong', children: text })) : (_jsx(Paragraph, { size: isSizeSmall ? 'standard' : 'large', color: disabled ? 'disabled' : 'default', children: text })), badgeProps.badgeVariant && !disabled && (_jsx(Badge, { variant: badgeProps.badgeVariant, text: badgeProps.badgeText, shape: badgeProps.badgeShape, type: badgeProps.badgeType, icon: badgeProps.badgeIcon, size: "small" }))] })] }));
};
